import Swal from "sweetalert2";

export default {
  name: "ForCRUDFunction",
  computed: {
    isEdit() {
      return this.$route.path.includes("edit");
    },
  },
  methods: {
    getHospitalData(id) {
      this.$requestService.get(
        this.$apiEndpoints.hospitals + `/${id}`,
        {},
        this.getHospitalDataSuccess,
      );
    },
    defineUSerCriteria(tab) {
      switch (tab) {
        case "treatingPhysicians":
          return "PHYSICIAN";
        case "dataManager":
          return "MANAGER";
        default:
          break;
      }
    },
    defineEndpoint() {
      const { tab } = this.$route.params;

      switch (tab) {
        case 'hospitals':
          return 'hospitals';
        case 'summaryPatientView':
          return 'summaryPatientView';
        case 'secondopinions':
          return 'secondopinions';
        default:
          return 'users';
      }
    },
    constructPayload({ customPayload, state }) {
      // define endpoint
      const { tab } = this.$route.params;
      const ep = this.defineEndpoint();

      let payload = {};

      // if endpoint is users
      if (ep === "users") {
        payload = this.constructPayloadUsers(customPayload, state);
      } else if (
        (ep === "hospitals" ||
          ep === "summaryPatientView" ||
          ep === "secondopinions") &&
        customPayload.criterias
      ) {
        if (customPayload.criterias.op === 'or') {
          payload = {
            criteria: { ...customPayload.criterias },
            paging: { ...customPayload.paging }
          }
          if (payload.criteria.of[0].value === "" || payload.criteria.of[0].value === null) {
            delete payload.criteria;
            delete this.$route.query.search;
            if (state !== 'mounted')
              this.$router.push({
                name: 'summary',
                params: { tab },
                query: { page: payload.paging?.page + 1 },
              })
          } else {
            if (state !== 'mounted')
              this.$router.push({
                name: 'summary',
                params: { tab },
                query: { 
                  page: payload.paging?.page + 1 || 1,
                  search: payload.criteria.of[0].value,
                },
              })
          }
        } else {
          // condition on hospital tab, only one object criteria
          payload = {
            criteria: {
              field: customPayload.criterias[0].field,
              op: "contains",
              value: customPayload.criterias[0].value || "",
            },
            paging: { ...customPayload.paging },
          };
          if (payload.criteria.value === "") {
            delete payload.criteria;
            delete this.$route.query.search;

            if (state !== 'mounted')
              this.$router.push({
                name: 'summary',
                params: { tab },
                query: { page: payload.paging?.page + 1 },
              })
          } else {
            if (state !== 'mounted')
              this.$router.push({
                name: 'summary',
                params: { tab },
                query: { 
                  page: payload.paging?.page + 1 || 1,
                  search: payload.criteria.value,
                },
              })
          }
        }
      }

      // if there is changes on page number, use default here
      // condition accepted on page load
      const { page } = payload?.paging || customPayload?.paging || 0;
      const paging = {
        paging: { 
          page,
          size: payload?.paging?.size || 5 
        },
      };

      payload = { 
        ...payload, 
        ...paging,
        sorting: [{
          field: 'creationDate',
          dir: 'DESC'
        }]
      };

      return { payload, ep }
    },
    // Construct payload for endpoint 'users'
    constructPayloadUsers(customPayload, state) {
      const { tab } = this.$route.params;
      const payload = {
        // default criteria
        criteria: {
          field: null,
          op: "and",
          value: null,
          of: [{
            field: "role",
            op: "is",
            value: this.defineUSerCriteria(tab),
          }],
        },
        paging: {
          ...customPayload.paging,
        },
      };

      if (customPayload.criterias && customPayload.criterias[0].value) {
        payload.criteria.of = [
          ...payload.criteria.of,
          ...customPayload.criterias,
        ];

        if (state !== 'mounted') {
          this.$router.push({
            name: 'summary',
            params: { tab },
            query: { 
              page: payload.paging?.page + 1 || 1,
              search: customPayload.criterias[0].value,
            },
          })
        }
      } else {
        // move "of" criteria to only one object
        payload.criteria.field = "role";
        payload.criteria.op = "is";
        payload.criteria.value = this.defineUSerCriteria(tab);

        delete payload.criteria.of;

        if (state !== 'mounted') {
          this.$router.push({
            name: 'summary',
            params: { tab },
            query: { page: payload.paging?.page + 1 || 1 },
          })
        }
      }

      return payload;
    },
    fetchData(customPayload = {}, state = null) {
      this.config.loading = true;
      
      const { payload, ep } = this.constructPayload({
        customPayload, 
        state
      })
      
      this.$requestService
        .post(
          this.$apiEndpoints[ep] + "/search",
          payload,
          this.handleFetchListSuccess,
          this.handleFetchListFailed
        )
        .then(() => {
          this.config.loading = false;
        });
    },
    fetchAfterSearch(payload, state) {
      this.config.loading = true;
      
      this.$requestService
        .post(
          this.$apiEndpoints[state] + "/search",
          payload,
          this.handleFetchListSuccess,
          this.handleFetchDataFailed
        )
        .then(() => {
          this.config.loading = false;
        });
    },
    handleFetchDataFailed(e) {
      this.$vToastify.error(e.message);
    },
    submitCreate(payload) {
      this.config.formLoading = true;

      const method = this.isEdit ? "put" : "post";

      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      const url = this.isEdit
        ? this.$apiEndpoints[ep] + `/${payload.id}`
        : this.$apiEndpoints[ep];

      this.$requestService[method](
        url,
        payload,
        this.handleSubmitSuccess,
        this.handleSubmitFailed
      ).then(() => {
        this.config.formLoading = false;
      });
    },
    handleSubmitSuccess() {
      // this.$vToastify.success();
      const tab = this.$route.params.tab;
      this.$router.push({
        name: "summary",
        params: { tab },
      });
    },
    handleSubmitFailed(e) {
      if (e.response.status === 400 || e.response.status === 422) {
        this.config.errors = e.response.data.errors;
      } else {
        this.$vToastify.error(e.message);
      }
    },
    deleteConfirm(row) {
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to delete this row ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
        customClass: {
          confirmButton: "confirmButton",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(row.id);
        }
      });
    },
    deleteData(id) {
      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      this.$requestService.delete(
        this.$apiEndpoints[ep] + "/" + id,
        {},
        this.handleDeleteSucess,
        this.handleDeleteFailed
      );
    },
    handleDeleteSucess() {
      this.$vToastify.success("Successfully Deleted");
      this.fetchData();
    },
    handleDeleteFailed() {
      this.$vToastify.error("Oops, something wromg");
    },
    populateForm() {
      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      this.$requestService.get(
        this.$apiEndpoints[ep] + `/${this.$route.params.id}`,
        {},
        this.handleGetSuccess,
        this.handleGetFailed
      );
    },
    handleGetSuccess(response) {
      this.config.formEdit = { ...response.data };
    },
  },
};
